.grape-ui-users button {
	border: none;
	text-align: center;
	display: inline-block;
	text-decoration: none;
}

.grape-ui-users .button-green {
	background-color: #4CAF50;
}
.grape-ui-users .button-blue { background-color: #008CBA; }
.grape-ui-users .button-orange { background-color: #FFBD1D; }
.grape-ui-users .button-purple { background-color: #792cff; }

.grape-ui-users .arrow-active {
	color: blue;
	background-color: red;
}

.grape-ui-users .arrow-inactive {
	color: grey;
}

.grape-ui-users .row-inactive {
	background-color: red;
}

.grape-ui-users .pagination {
	display: inline-block;
}

.grape-ui-users .pagination a {
	color: black;
	float: left;
	padding: 8px 16px;
	text-decoration: none;
	border-radius: 5px;
} 

.grape-ui-users .pagination a.active {
	background-color: #4CAF50;
	color: white;
	border-radius: 5px;
}

.psu-page-header {
	font-size: 2em;
	margin: 1em;
}

.psu-field-title {
	font-weight: bold; 
	text-align: right;
}

.page_submenu_item { 
	color: #439FE9; 
	border-color: #439FE9; 
}
